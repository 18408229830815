<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">费用管理列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 添加 删除 下拉框 按钮部分 -->
                <div class="out_sel_btn">
                    <div class="sel_inp">
                        <el-select v-model="campus_id" placeholder="校区" clearable @change="initData" size="mini">
                            <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name" :value="item.uid">
                            </el-option>
                        </el-select>
                        <el-input placeholder="请输入费用名称" v-model="search_inp" clearable @change="initData" size="mini">
                        </el-input>
                    </div>
                    <div class="add_del_btn">
                        <el-button type="primary" class="addBtn" @click="addition">添加</el-button>
                        <!-- <el-button class="delBtn" @click="deltion">删除</el-button> -->
                    </div>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="uid" label="ID">
                        </el-table-column>
                        <el-table-column prop="name" label="费用名称">
                        </el-table-column>
                        <el-table-column prop="campus_uid" label="校区">
                        </el-table-column>
                        <el-table-column prop="class_uid" label="班级">
                        </el-table-column>
                        <el-table-column prop="financial_type" label="缴费类型">
                        </el-table-column>
                        <el-table-column prop="send_day" label="发送日期">
                        </el-table-column>
                        <el-table-column prop="price" label="金额">
                        </el-table-column>
                        <el-table-column prop="price_img" label="照片">
                            <template slot-scope="scope">
                                <span v-if="scope.row.price_img == null">无</span>
                                <img style="width:20px;height:20px" v-else :src="scope.row.price_img">
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态">
                        </el-table-column>
                        <!-- <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                <span @click="handle_Delet(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 -->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="180px">
                <el-form-item label="费用名称">
                    <el-input v-model="outlay_name" placeholder="请输入费用名称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="校区">
                    <el-select v-model="school_id" placeholder="请选择校区" clearable multiple @change="handle_school"
                        :disabled="dis_name" size="mini" ref="sel">
                        <el-option v-for="item in school_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="班级">
                    <el-select v-model="class_id" placeholder="请选择班级" clearable multiple :disabled="dis_name"
                        size="mini" ref="selec" @change="handle_sel">
                        <el-option v-for="item in class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="缴费类型">
                    <el-radio v-model="pay_radio" label="1">月度</el-radio>
                    <el-radio v-model="pay_radio" label="2">季度</el-radio>
                </el-form-item>
                <el-form-item label="金额">
                    <el-input v-model="money_num" placeholder="请输入金额(元)" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="缴费推送日期">
                    <el-input v-model="pay_date" placeholder="请输入缴费推送日期(1-28号的数字)" clearable size="mini"></el-input>
                    <!-- <el-date-picker v-model="pay_date" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker> -->
                </el-form-item>
                <el-form-item label="上传照片(付款码)" class="img_load">
                    <div style="display:flex">
                        <el-input style="width:376px;height:30px" placeholder="请上传照片(付款码)" disabled></el-input>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/financial_upload"
                            :headers=myHeaders name="image" :on-success="handleSuccess_img" :show-file-list="false"
                            accept=".png,.gif,.jpg,.jpeg">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传照片
                            </el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item class="video_img" v-if="show">
                    <img :src="img_url" alt="">
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1">正常</el-radio>
                    <el-radio v-model="radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <!-- <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn"> -->
        <!-- 标题 -->
        <!-- <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div> -->
        <!-- <div class="del_content">{{del_tip}}</div> -->
        <!-- 提交 取消 按钮 -->
        <!-- <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div> -->
        <!-- </el-dialog> -->
    </div>
</template>

<script>
let auth_token = sessionStorage.getItem("Token");
import Paging from '@/components/Paging';
import { getPayApi, showPayApi, selPayApi, addPayApi, editPayApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // token
            myHeaders: {
                'Token': auth_token
            },
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            // *********表单验证内容*********
            outlay_name: '',   //费用名称
            school_arr: [],   //校区
            school_id: [],
            class_arr: [],   //班级
            class_id: [],
            money_num: '',   //金额
            pay_date: '',      //缴费日期
            pay_radio: '1',   //缴费类型
            pay_state: 1,
            push_date: '',   //发送时间
            radio: '1',   //状态
            fin_state: 1,
            // 上传图片地址
            img_url: '',
            // 视频图片是否显示
            show: false,
            dis_name: false,
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getPayApi({
                page: this.page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    this.campus_arr = res.campus;  //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
        // 点击添加按钮
        addition () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showPayApi({
                auth_userid: this.user_id,   //登录用户id
                type: 1,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_arr = res.campus;   //校区名称
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 校区下拉框改变
        handle_school () {
            if (this.school_id.length == 0) {
                this.class_arr = [];
                this.class_id = [];
            } else {
                this.class_arr = [];
                this.class_id = [];
                selPayApi({
                    auth_userid: this.user_id,   //登录用户id
                    campus_uid: this.school_id,
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.class_arr = res.class;   //班级
                        setTimeout(() => {
                            this.$refs.sel.blur()
                        }, 4000)
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 自动隐藏下拉框
        handle_sel () {
            setTimeout(() => {
                this.$refs.selec.blur()
            }, 4000)
        },
        // 照片上传成功
        handleSuccess_img (res, file, fileList) {
            if (res.status.code == 200) {
                this.show = true;
                this.$message.success('图片上传成功！');
                this.img_url = res.status.img;
            } else {
                this.show = false;
                this.$message.error(res.status.msg);
            }
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.add_edit_dialog = true;
            this.id = id;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            this.dis_name = true;
            showPayApi({
                auth_userid: this.user_id,   //登录用户id
                type: 2,
                id: id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_arr = res.campus;   //校区名称
                    this.class_arr = res.class;   //班级
                    this.outlay_name = res.data.name;   //名称
                    this.school_id = res.data.campus_uid;   //校区id
                    this.class_id = res.data.class_uid;   //班级id
                    this.pay_date = res.data.send_day;   //发送时间
                    this.pay_state = res.data.financial_type;   //费用类型
                    if (this.pay_state == "1") {
                        this.pay_radio = '1'
                    } else {
                        this.pay_radio = '2'
                    };
                    this.money_num = res.data.price;   //价格
                    this.img_url = res.data.price_img;   //图片
                    if (this.img_url != '') {
                        this.show = true;
                    };
                    this.fin_state = res.data.status;   //状态
                    if (this.fin_state == 1) {
                        this.radio = '1'
                    } else {
                        this.radio = '2'
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加  编辑 点击提交按钮
        handle_confirm () {
            if (this.radio == '1') {
                this.fin_state = 1;
            } else if (this.radio == '2') {
                this.fin_state = 2;
            };
            if (this.pay_radio == '1') {
                this.pay_state = 1;
            } else if (this.radio == '2') {
                this.pay_state = 2;
            };
            if (this.title_name == '添加') {
                addPayApi({
                    name: this.outlay_name,  //名称
                    campus_uid: this.school_id, //校区id
                    class_uid: this.class_id, // 班级id
                    send_day: this.pay_date,  //发送日期
                    financial_type: this.pay_state,  //缴费类型
                    status: this.fin_state,  // 状态
                    price: this.money_num,   //价格
                    price_img: this.img_url,   //图片
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editPayApi({
                    id: this.id,
                    name: this.outlay_name,  //名称
                    campus_uid: this.school_id, //校区id
                    class_uid: this.class_id, // 班级id
                    send_day: this.pay_date,  //发送日期
                    financial_type: this.pay_state,  //缴费类型
                    status: this.fin_state,  // 状态
                    price: this.money_num,   //价格
                    price_img: this.img_url,   //图片
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加  编辑  点击重置按钮  弹窗点击遮罩关闭
        handle_reset () {
            this.outlay_name = '';  //名称
            this.school_id = [];    //校区id
            this.class_id = [];   //班级id
            this.pay_date = '';    //发送时间
            this.pay_radio = '1';   //费用类型
            this.money_num = '';     //价格
            this.radio = '1';   //状态
            this.show = false;   //图片
            this.img_url = '';
            this.dis_name = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 下拉框  添加  删除按钮部分
        .out_sel_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 下拉框部分
            .sel_inp {
                margin-top: 15px;
                display: flex;
                .el-select {
                    width: 140px;
                    height: 30px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    ::v-deep.el-input__inner {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-select__caret {
                            line-height: 30px;
                        }
                        ::v-deep.el-input__icon {
                            line-height: 30px;
                        }
                    }
                }
                .el-input {
                    width: 155px;
                    height: 30px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left: 0px;
                    ::v-deep.el-input__inner {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                    }
                }
            }
            // 添加  删除按钮部分
            .add_del_btn {
                text-align: right;
                .el-button {
                    width: 80px;
                    height: 30px;
                    line-height: 4px;
                }
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 30px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 376px;
                    height: 30px;
                }
            }
            .video_img {
                img {
                    width: 100px;
                    height: 100px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>